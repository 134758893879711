<script setup>

import HeaderComponent from "@/components/Header/HeaderComponent.vue";
import FooterComponent from "@/components/Footer/FooterComponent.vue";
import {inject} from "vue";

const rootScope = inject('rootScope');
</script>

<template>
  <HeaderComponent/>
  <main>
    <router-view/>
  </main>

  <FooterComponent v-show="rootScope.logged"/>
</template>

<style scoped>

</style>
