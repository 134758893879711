<script setup>

import {onMounted} from "vue";
import router from "@/router";

onMounted(() =>{
  router.push('/')
})

</script>

<template>
   <h1> Pagina no encontrada</h1>
</template>

<style scoped>

</style>
