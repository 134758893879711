import {createApp, reactive} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import '@/assets/css/styles.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


const app = createApp(App);

app.use(router);

const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674',
};
app.use(VueSweetalert2, options);
// providers
const state = reactive({
    loading: false,
    logged: false,
    name: '',
    user: 0,
    saldo_creditos: 0,
    saldo_acumulado: 0,
    registro_completo: 0,
    producto: {
        descripcion : '',
        credito: 0,
        productoId: 0
    }
})

app.provide(/* key */ 'rootScope', /* value */ state);

app.mount('#app');

