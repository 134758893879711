<script setup>

</script>

<template>
  <footer class="footer">
    <div class="footer-contain">
      <router-link to="/" class="footer-menu">
        <i class="fa-solid fa-house"></i>
        <p>Inicio</p>
      </router-link>
<!--      <router-link to="/" class="footer-menu">-->
<!--        <i class="fa-solid fa-plus"></i>-->
<!--        <p>Acumular</p>-->
<!--      </router-link>-->
      <router-link to="/points-details" class="footer-menu">
        <i class="fa-regular fa-newspaper"></i>
        <p>Historial</p>
      </router-link>
      <router-link  to="/ranking" class="footer-menu">
        <i class="fa-solid fa-ranking-star"></i>
        <p>Ranking</p>
      </router-link>
    </div>

    <a class="chat" href="https://wa.me/+573505529223" target="_blank">
      <picture>
        <img src="https://images-development-codi.s3.amazonaws.com/postobon-frisby/CHAT.png" alt="chat">
      </picture>
    </a>
  </footer>
</template>

<style scoped>

</style>
