<template>
  <Loader :loading="rootScope.loading"/>
  <router-view/>
</template>

<style>

</style>

<script setup>

import Loader from "@/components/Loader/LoaderComponent.vue";
import {inject} from "vue";

const rootScope = inject('rootScope');

</script>
